import React from "react";
import { Card, Heading, Box, Flex } from "theme-ui";
import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { InfoMessage } from "../../../InfoMessage";
import { UnstakeAndClaim } from "../UnstakeAndClaim";
import { RemainingLQTY } from "../RemainingLQTY";
import { StaticRow } from "../../../Trove/Editor";
import { GT, LP } from "../../../../strings";

const selector = ({ usdcLiquidityMiningStake, usdcLiquidityMiningLQTYReward }: LiquityStoreState) => ({
  usdcLiquidityMiningStake,
  usdcLiquidityMiningLQTYReward
});

export const Disabled: React.FC = () => {
  const { usdcLiquidityMiningStake, usdcLiquidityMiningLQTYReward } = useLiquitySelector(selector);
  const hasStake = !usdcLiquidityMiningStake.isZero;

  return (
    <Card>
      <Heading>
        xUSD-ETH SLP Farm
        <Flex sx={{ justifyContent: "flex-end" }}>
          <RemainingLQTY />
        </Flex>
      </Heading>
      <Box sx={{ p: [2, 3] }}>
        <InfoMessage title="Liquidity farming period has finished">
          <Flex>There are no more XDO rewards left to farm</Flex>
        </InfoMessage>
        {hasStake && (
          <>
            <Box sx={{ border: 1, pt: 3, borderRadius: 3 }}>
              <StaticRow
                label="Stake"
                inputId="farmusdc-deposit"
                amount={usdcLiquidityMiningStake.prettify(4)}
                unit={LP}
              />
              <StaticRow
                label="Reward"
                inputId="farmusdc-reward"
                amount={usdcLiquidityMiningLQTYReward.prettify(4)}
                color={usdcLiquidityMiningLQTYReward.nonZero && "success"}
                unit={GT}
              />
            </Box>
            <UnstakeAndClaim />
          </>
        )}
      </Box>
    </Card>
  );
};
