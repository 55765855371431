import { useEffect, useState } from "react";
import { fetchXdoXusdPrices } from "../components/fetchXdoXusdPrices";

const useXdoXusdPrices = () => {
  const [xdoPrice, setXdoPrice] = useState<string>("0");
  const [xusdPrice, setXusdPrice] = useState<string>("0");
  useEffect(() => {
    (async () => {
      try {
        const { xusdPrice, xdoPrice } = await fetchXdoXusdPrices();
        setXdoPrice(xdoPrice);
        setXusdPrice(xusdPrice);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return { xdoPrice, xusdPrice };
};
export default useXdoXusdPrices;
