import React from "react";
import Tippy, { TippyProps } from "@tippyjs/react";
import { Icon } from "./Icon";
import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

export type InfoIconProps = Pick<TippyProps, "placement"> &
  Pick<FontAwesomeIconProps, "size"> & {
    tooltip: React.ReactNode;
  };

export const InfoWalletIcon: React.FC<InfoIconProps> = ({
  placement = "bottom",
  tooltip,
  size = "1x"
}) => {
  return (
    <Tippy interactive={true} placement={placement} content={tooltip} maxWidth="268px">
      <span>
        &nbsp;
        <Icon name="wallet" size={size} />
      </span>
    </Tippy>
  );
};
