export enum ChainType{
    Avalanche_Mainnet,
    Avalanche_Fuji_Testnet,
    Arbitrum_Testnet_Rinkeby,
    Arbitrum_One
}

interface RPCDetail {
    chainId: string,
    chainName: string,
    nativeCurrency: {
        name: string,
        symbol: string,
        decimals: number
    }
    rpcUrls: [string],
    blockExplorerUrls: [string]
}
const GetChainDetail = (chainName: ChainType) => {
    //id to Hexadecimal  https://www.rapidtables.com/convert/number/decimal-to-hex.html
      switch (chainName) {
          case ChainType.Avalanche_Mainnet:
             return {
                  chainId: "0xa86a", //43114
                  chainName: "Avalanche Network",
                  nativeCurrency: {
                      name: "Avalanche",
                      symbol: "AVAX",
                      decimals: 18
                  },
                  rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
                  blockExplorerUrls: ["https://cchain.explorer.avax.network/"]
              } as RPCDetail;
          case ChainType.Arbitrum_Testnet_Rinkeby:
              return {
                  chainId: "0x66EEB",
                  chainName: "Arbitrum Testnet Rinkeby",
                  nativeCurrency: {
                      name: "Arbitrum",
                      symbol: "ARETH",
                      decimals: 18
                  },
                  rpcUrls: ["https://rinkeby.arbitrum.io/rpc"],
                  blockExplorerUrls: ["https://rinkeby-explorer.arbitrum.io/"]
              } as RPCDetail;
          case ChainType.Arbitrum_One:
               return {
                  chainId: "0xA4B1",
                  chainName: "Arbitrum One",
                  nativeCurrency: {
                      name: "Arbitrum",
                      symbol: "AETH",
                      decimals: 18
                  },
                  rpcUrls: ["https://arb1.arbitrum.io/rpc"],
                  blockExplorerUrls: ["https://arbiscan.io"]
              } as RPCDetail;
          default:
              return {
                  chainId: "0xA4B1",
                  chainName: "Arbitrum One",
                  nativeCurrency: {
                      name: "Arbitrum",
                      symbol: "AETH",
                      decimals: 18
                  },
                  rpcUrls: ["https://arb1.arbitrum.io/rpc"],
                  blockExplorerUrls: ["https://arbiscan.io"]
              } as RPCDetail;
    }
}

export default GetChainDetail;