import React from "react";
import { Flex, Container } from "theme-ui";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { Wallet } from "@ethersproject/wallet";

import { Decimal, Difference, Trove } from "@liquity/lib-base";
import { LiquityStoreProvider } from "@liquity/lib-react";

import { useLiquity } from "./hooks/LiquityContext";
import { TransactionMonitor } from "./components/Transaction";
import { UserAccount } from "./components/UserAccount";
import { SystemStatsPopup } from "./components/SystemStatsPopup";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { PageSwitcher } from "./pages/PageSwitcher";
import { Farm } from "./pages/Farm";
//import { Bridge } from "./pages/Bridge";
import { RiskyTrovesPage } from "./pages/RiskyTrovesPage";
import { RedemptionPage } from "./pages/RedemptionPage";

import { TroveViewProvider } from "./components/Trove/context/TroveViewProvider";
import { StabilityViewProvider } from "./components/Stability/context/StabilityViewProvider";
import { StakingViewProvider } from "./components/Staking/context/StakingViewProvider";
import { FarmViewProvider } from "./components/Farm/context/FarmViewProvider";
import { UsdcFarmViewProvider } from "./components/FarmUsdc/context/FarmViewProvider";
import { DaiFarmViewProvider } from "./components/FarmDai/context/FarmViewProvider";
import { Background } from "./components/Background";

type LiquityFrontendProps = {
  loader?: React.ReactNode;
};
export const LiquityFrontend: React.FC<LiquityFrontendProps> = ({ loader }) => {
  const { account, provider, liquity } = useLiquity();

  // For console tinkering ;-)
  Object.assign(window, {
    account,
    provider,
    liquity,
    Trove,
    Decimal,
    Difference,
    Wallet
  });

  return (
    <LiquityStoreProvider {...{ loader }} store={liquity.store}>
      <Router>
        <TroveViewProvider>
          <StabilityViewProvider>
            <StakingViewProvider>
              <FarmViewProvider>
                <UsdcFarmViewProvider>
                  <DaiFarmViewProvider>
                    <Flex sx={{ flexDirection: "column", minHeight: "100%" }}>
                      <Header>
                        <UserAccount />
                        <SystemStatsPopup />
                      </Header>

                      <Container
                        variant="main"
                        sx={{
                          display: "flex",
                          flexGrow: 1,
                          flexDirection: "column",
                          alignItems: "center"
                        }}
                      >
                        {Background("layout.blueBackground")}
                        {Background("layout.purpleBackground")}
                        <Switch>
                          <Route path="/" exact>
                            <PageSwitcher />
                          </Route>
                          <Route path="/farm">
                            <Farm />
                          </Route>
                          {/* <Route path="/bridge">
                            <Bridge />
                          </Route> */}
                          <Route path="/risky-troves">
                            <RiskyTrovesPage />
                          </Route>
                          <Route path="/redemption">
                            <RedemptionPage />
                          </Route>
                        </Switch>
                      </Container>

                      <Footer></Footer>
                    </Flex>
                  </DaiFarmViewProvider>
                </UsdcFarmViewProvider>
              </FarmViewProvider>
            </StakingViewProvider>
          </StabilityViewProvider>
        </TroveViewProvider>
      </Router>
      <TransactionMonitor />
    </LiquityStoreProvider>
  );
};
