import { Decimal } from "@liquity/lib-base";

type UniswapResponse = {
  data?: {
    bundle: {
      ethPrice: string;
    } | null;
    pair: {
      reserveUSD: string;
      token1Price: string;
      totalSupply: string;
    },
    token: {
      derivedETH: string;
    } | null;
  };
  errors?: Array<{ message: string }>;
};

// still use token from AVAX to get the prices
const uniswapQuery = (/* lqtyTokenAddress: string */) => `{
  token(id: "0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664") {
    derivedETH
  },
  pair(id: "0x4a6993d6bd24993f131f8ac2ac0b0fe0e6e71713") {
    totalSupply
    reserveUSD
    token1Price
  }
  bundle(id: 1) {
    ethPrice
  },
}`;

export async function fetchXdoPriceFromDai() {
  const response = await window.fetch("https://api.thegraph.com/subgraphs/name/pangolindex/exchange", {
    method: "POST",
    headers: {
      "content-type": "application/json"
    },
    body: JSON.stringify({
      query: uniswapQuery(),
      variables: null
    })
  });
  if (!response.ok) {
    return Promise.reject("Network error connecting to sushiswap subgraph");
  }

  const { data, errors }: UniswapResponse = await response.json();

  if (errors) {
    return Promise.reject(errors);
  }

  if (typeof data?.token?.derivedETH === "string" && typeof data?.bundle?.ethPrice === "string") {
    const ethPriceUSD = Decimal.from(data.bundle.ethPrice);
    const lqtyPriceUSD = ethPriceUSD.mul(data.pair.token1Price);

    return { lqtyPriceUSD };
  }

  return Promise.reject("SushiSwap doesn't have the required data to calculate yield");
}

/* export async function fetchXdoPrice(lqtyTokenAddress: string) {
  const response = await window.fetch("https://api.thegraph.com/subgraphs/name/pangolindex/exchange", {
    method: "POST",
    headers: {
      "content-type": "application/json"
    },
    body: JSON.stringify({
      query: uniswapQuery(lqtyTokenAddress),
      variables: null
    })
  });
  if (!response.ok) {
    return Promise.reject("Network error connecting to Pangolin subgraph");
  }

  const { data, errors }: UniswapResponse = await response.json();

  if (errors) {
    return Promise.reject(errors);
  }

  if (typeof data?.token?.derivedETH === "string" && typeof data?.bundle?.ethPrice === "string") {
    const ethPriceUSD = Decimal.from(data.bundle.ethPrice);
    const lqtyPriceUSD = Decimal.from(data.token.derivedETH).mul(ethPriceUSD).mul(data.pair.token1Price);

    return { lqtyPriceUSD };
  }

  return Promise.reject("Pangolin doesn't have the required data to calculate yield");
}
*/