type GraphResponse = {
  data?: {
    bundle: {
      ethPrice: string;
    } | null;
    xusdusdc: {
      token1Price: string;
    } | null;
    token: {
      derivedETH: string;
    } | null;
    xusdxdo: {
      token0Price: string;
    } | null;
  };
  errors?: Array<{ message: string }>;
};

const xdoxusdQuery = () => `{
  xusdusdc:pair(id:"0x58ffd50866c678a91f9a1d6bddeeb5638591cb38"){
     token1Price
  },
  xusdusdc:pair(id:"0x58ffd50866c678a91f9a1d6bddeeb5638591cb38"){
     token1Price
  },
  xusdxdo:pair(id:"0xcd4b25ab32ad3b6190773b4d10a1526b8b870e2e"){
     token0Price,
  },
}`;

export async function fetchXdoXusdPrices() {
  // First figuring out the XDO price.
  const priceResponses = await window.fetch("https://api.thegraph.com/subgraphs/name/sushiswap/arbitrum-exchange", {
    method: "POST",
    headers: {
      "content-type": "application/json"
    },
    body: JSON.stringify({
      query: xdoxusdQuery(),
      variables: null
    })
  });

  if (!priceResponses.ok) {
    return Promise.reject("Network error connecting to SushiSwap subgraph");
  }

  const priceResponseJSON: GraphResponse = await priceResponses.json();

  if (priceResponseJSON.errors) {
    return Promise.reject(priceResponseJSON.errors);
  }

  if (
    typeof priceResponseJSON.data?.xusdusdc?.token1Price === "string" &&
    typeof priceResponseJSON.data?.xusdxdo?.token0Price=== "string"
  ) {
    // const ethPrice = parseFloat(priceResponseJSON.data.bundle.ethPrice);
    // const xusdEthPrice = parseFloat(priceResponseJSON.data.token.derivedETH);
    // const xdoPrice = parseFloat((ethPrice * xusdEthPrice).toFixed(6)).toFixed(2);
    const xusdPrice = (parseFloat(priceResponseJSON.data.xusdusdc.token1Price)).toFixed(2);
    let xdoPrice = (parseFloat(priceResponseJSON.data.xusdxdo.token0Price) * parseFloat(priceResponseJSON.data.xusdusdc.token1Price)).toFixed(2);
    return { xusdPrice, xdoPrice };
  }

  return Promise.reject("The Graph doesn't have the required data to calculate prices");
}