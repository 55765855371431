import React from "react";
import { Container, Flex, Box, Link } from "theme-ui";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export const Footer: React.FC = () => {
  const footerIcon = {
    marginRight: "25px",
    color: "white",
    float: "left"
  } as React.CSSProperties;
  return (
    <Container variant="layout.footer">
      <Flex sx={{ alignItems: "center", marginLeft: "auto", marginRight: "auto" }}>
        <Box as="nav" sx={{ m: 3, mt: 1, p: 0 }}>
          {/* <Link href="https://xdollar.fi/">
          <FontAwesomeIcon
            icon={["fab", "medium"]}
            style={footerIcon}
            size="lg"
          />
        </Link> */}
          <Link target="_blank" style={footerIcon} href="https://xdollar.fi/">
            Website
          </Link>
          <Link target="_blank" style={footerIcon} href="https://xdollarfi.medium.com/">
            Medium
          </Link>
          <Link target="_blank" style={footerIcon} href="https://twitter.com/xDollarFi">
            Twitter
          </Link>
          <Link target="_blank" style={footerIcon} href="https://docs.xdollar.fi/">
            Gitbook
          </Link>
          <Link target="_blank" style={footerIcon} href="https://discord.com/invite/c8c7Wm2EEx">
            Discord
          </Link>
          <Link target="_blank" style={footerIcon} href="https://t.me/xdollar_en">
            Telegram
          </Link>
          <Link
            target="_blank"
            style={footerIcon}
            href="https://github.com/xDollar-Finance/xDollar-contracts"
          >
            Github
          </Link>
          <Link
            target="_blank"
            style={footerIcon}
            href="https://github.com/xDollar-Finance/xDollar-contracts/blob/main/xDollar%20-%20Smart%20Contract%20Audit%20v210624.pdf"
          >
            Audit Report
          </Link>
          <Link target="_blank" style={footerIcon} href="https://defillama.com/protocol/xdollar">
            Defi Llama
          </Link>
        </Box>
      </Flex>
    </Container>
  );
};
