import React, { useEffect, useState } from "react";
import { Card, Paragraph, Text } from "theme-ui";
import { Decimal, LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { InfoIcon } from "../../InfoIcon";
import { useLiquity } from "../../../hooks/LiquityContext";
import { Badge } from "../../Badge";
import { fetchPrices } from "../context/fetchPrices";

const selector = ({
  remainingUSDCLiquidityMiningLQTYReward,
  totalStakedUSDCUniTokens
}: LiquityStoreState) => ({
  remainingUSDCLiquidityMiningLQTYReward,
  totalStakedUSDCUniTokens
});

export const Yield: React.FC = () => {
  const {
    liquity: {
      connection: { addresses }
    }
  } = useLiquity();

  const { remainingUSDCLiquidityMiningLQTYReward, totalStakedUSDCUniTokens } = useLiquitySelector(selector);
  const [xUSDPrice, setLqtyPrice] = useState<Decimal | undefined>(undefined);
  const [sushixUSDMaticLpPrice, setUniLpPrice] = useState<Decimal | undefined>(undefined);
  const hasZeroValue = remainingUSDCLiquidityMiningLQTYReward.isZero || totalStakedUSDCUniTokens.isZero;
  //const lqtyTokenAddress = addresses["lqtyToken"];
  const MATICTokenAddress = "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174"; //TODO: WMATIC address on polygon, hardcode it to addresses lib-ethers\utils:deploy.ts:deployContracts:addesses
  const sushixUSDMaticLpAddress = addresses["usdcUniToken"];
  const secondsRemaining = remainingUSDCLiquidityMiningLQTYReward.div(Decimal.from(0.05787037037037));
  const daysRemaining = secondsRemaining.div(60 * 60 * 24);

  useEffect(() => {
    (async () => {
      try {
        const { xdoPriceUSD, sushixUSDMaticLpPriceUSD } = await fetchPrices(sushixUSDMaticLpAddress);
        setLqtyPrice(xdoPriceUSD);
        setUniLpPrice(sushixUSDMaticLpPriceUSD);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [MATICTokenAddress, sushixUSDMaticLpAddress]);

  if (hasZeroValue || xUSDPrice === undefined || sushixUSDMaticLpPrice === undefined) return null;

  const remainingLqtyInUSD = remainingUSDCLiquidityMiningLQTYReward.mul(xUSDPrice);
  const totalStakedUniLpInUSD = totalStakedUSDCUniTokens.mul(sushixUSDMaticLpPrice);
  const yieldPercentage = remainingLqtyInUSD.div(totalStakedUniLpInUSD).mul(100);

  if (yieldPercentage.isZero) return null;

  return (
    <Badge>
      <Text>
        APR {yieldPercentage.mul(365).div(daysRemaining).toString(2)}%
      </Text>
      <InfoIcon
        tooltip={
          <Card variant="tooltip" sx={{ minWidth: ["auto", "352px"] }}>
            <Paragraph>
              An <Text sx={{ fontWeight: "bold" }}>estimate</Text> of the XDO return on staked SushiSwap
              LP tokens. The farm runs for 1 month ({daysRemaining.prettify(0)} days remaining), and the return is relative to the time remaining.
            </Paragraph>
            <Paragraph sx={{ fontSize: "12px", fontFamily: "monospace", mt: 2 }}>
              ($XDO_REWARDS / $STAKED_SushiSwap_LP) * 100 ={" "}
              <Text sx={{ fontWeight: "bold" }}> Yield</Text>
            </Paragraph>
            <Paragraph sx={{ fontSize: "12px", fontFamily: "monospace" }}>
              ($
              {remainingLqtyInUSD.shorten()} / ${totalStakedUniLpInUSD.shorten()}) * 100 =
              <Text sx={{ fontWeight: "bold" }}> {yieldPercentage.toString(2)}%</Text>
            </Paragraph>
          </Card>
        }
      ></InfoIcon>
    </Badge>
  );
};
