declare const window: any;
const buyXdoLink = ' https://app.sushi.com/swap?inputCurrency=0x3509f19581aFEDEff07c53592bc0Ca84e4855475&outputCurrency=0x9eF758aC000a354479e538B8b2f01b917b8e89e7';
const buyXusdLink = 'https://app.sushi.com/swap?inputCurrency=eth&outputCurrency=0x3509f19581aFEDEff07c53592bc0Ca84e4855475';
const addXdoToken = () =>
  addToken({
    type: "ERC20",
    options: {
      address: "0x9eF758aC000a354479e538B8b2f01b917b8e89e7",
      symbol: "XDO",
      decimals: 18,
      image: "https://assets.coingecko.com/coins/images/16289/small/logo-web-transparent_2.png"
    }
  });

const addXusdToken = () =>
  addToken({
    type: "ERC20",
    options: {
      address: "0x3509f19581aFEDEff07c53592bc0Ca84e4855475",
      symbol: "XUSD",
      decimals: 18,
      image:
        "https://assets.coingecko.com/coins/images/16291/small/xUSD-web-transparent.png?1623661715"
    }
  });
const addToken = (params: any) =>
  window.ethereum
    .request({ method: "wallet_watchAsset", params })
    .then(console.log("Success, Token added!"))
    .catch((error: Error) => console.log(`Error: ${error.message}`));

export { addXdoToken, addXusdToken, buyXdoLink, buyXusdLink };
